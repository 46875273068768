import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { LinkedIn, Facebook, Twitter, Instagram } from '@mui/icons-material';
import './Footer.css'; // Import CSS

function Footer() {
  return (
    <Box sx={{ backgroundColor: '#1c1c1c', color: 'white', padding: '50px' }}>
      <Grid container spacing={3}>
        {/* Explore Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>Explore</Typography>
          <ul className="footer-list">
            <li><Link to="/create-course" className="footer-link">Teach on LCM</Link></li>
            <li><Link to="/about-us" className="footer-link">About us</Link></li>
            <li><Link to="/features" className="footer-link">Features</Link></li>
          </ul>
        </Grid>

        {/* Support Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>Support</Typography>
          <ul className="footer-list">
            <li><Link to="/blog" className="footer-link">Blog</Link></li>
            <li><Link to="/affiliate" className="footer-link">Affiliate</Link></li>
            <li><Link to="/investors" className="footer-link">Investors</Link></li>
          </ul>
        </Grid>

        {/* Legal Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>Legal</Typography>
          <ul className="footer-list">
            <li><Link to="/terms" className="footer-link">Terms</Link></li>
            <li><Link to="/privacy-policy" className="footer-link">Privacy policy</Link></li>
            <li><Link to="/contact-us" className="footer-link">Contact us</Link></li>
          </ul>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom align="center">Follow us</Typography>
          <Box className="social-icons" sx={{ textAlign: 'center', marginTop: '10px' }}>
            <IconButton href="https://www.linkedin.com" target="_blank" color="inherit">
              <LinkedIn />
            </IconButton>
            <IconButton href="https://www.facebook.com" target="_blank" color="inherit">
              <Facebook />
            </IconButton>
            <IconButton href="https://twitter.com" target="_blank" color="inherit">
              <Twitter />
            </IconButton>
            <IconButton href="https://www.instagram.com" target="_blank" color="inherit">
              <Instagram />
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {/* Bottom Section with Logo and Copyright */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginTop: '30px',
          paddingTop: '10px',
          borderTop: '1px solid #444',
        }}
      >
        {/* Logo at Bottom Left */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="/lcm-logo.png" alt="LCMGO Logo" style={{ width: '100px', height: 'auto' }} />
        </Box>

        {/* Copyright at Bottom Right */}
        <Typography variant="body2"> Copyright © 2025 LCM. All Rights Reserved.</Typography>
      </Box>
    </Box>
  );
}

export default Footer;
