import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../../contexts/UserContext';
import './Navbar.css';

function Navbar() {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false); // Dropdown for search
  const [searchQuery, setSearchQuery] = useState('');
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
    setProfileAnchorEl(null)
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      setSearchDropdownOpen(false); // Close the dropdown
      navigate(`/search-results?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  // const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  // const handleMenuClose = () => setAnchorEl(null);

  const handleProfileMenuClick = (event) => setProfileAnchorEl(event.currentTarget);
  const handleProfileMenuClose = () => setProfileAnchorEl(null);

  const toggleDrawer = (open) => () => setDrawerOpen(open);

  const toggleSearchDropdown = () => setSearchDropdownOpen((prev) => !prev);

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'black', color: 'white' }} elevation={1}>
        <Toolbar sx={{ minHeight: 48, paddingX: 2 }}>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
            <Link to="/">
              <img src="/lcm-logo.png" alt="LCM Logo" className="logo" />
            </Link>
          </Box>

          {isMobile ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {/* Mobile Search Icon */}
              <IconButton color="inherit" onClick={toggleSearchDropdown}>
                <SearchIcon />
              </IconButton>
              {/* Hamburger Menu */}
              <IconButton color="inherit" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
           </Box>
          ) : (
            <>
              {/* <Button component={Link} to="/categories" sx={{ color: 'white', marginRight: 4, textTransform: 'none' }}>
                Categories
              </Button> */}
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search for courses"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                sx={{
                  flexGrow: 1,
                  maxWidth: '500px',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {/* Desktop Buttons */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
              {/* <Button
                onClick={handleMenuClick}
                endIcon={<ArrowDropDownIcon />}
                sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}
              >
                Teach
              </Button>

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => { handleMenuClose(); navigate('/pricing'); }}>Pricing</MenuItem>
                <MenuItem onClick={() => { handleMenuClose(); navigate('/create-course'); }}>Create Course</MenuItem>
                <MenuItem onClick={() => { handleMenuClose(); navigate('/my-teachings'); }}>My Teachings</MenuItem>
              </Menu> */}
              <Button component={Link} to="/pricing" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
                Pricing
              </Button>
              <Button component={Link} to="/features" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
                Features
              </Button>
              <Button component={Link} to="/referral-discount" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
                Referral Discount
              </Button>

              <Button component={Link} to="/create-course" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
                Create Course
              </Button>
              <Button component={Link} to="/my-teachings" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
                My Teachings
              </Button>
              <Button component={Link} to="/my-learnings" sx={{ color: 'white', textTransform: 'none', marginRight: 2 }}>
                My Learnings
              </Button>
              



              {user ? (
                <>
                  <IconButton onClick={handleProfileMenuClick}>
                    <Avatar
                      src={user?.profilePic }
                      alt={user?.name || 'Profile'}
                      sx={{ width: 32, height: 32 }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={profileAnchorEl}
                    open={Boolean(profileAnchorEl)}
                    onClose={handleProfileMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    <MenuItem onClick={() => { handleProfileMenuClose(); navigate('/my-profile'); }}>
                      My Profile
                    </MenuItem>
                    {/* <MenuItem onClick={() => { handleProfileMenuClose(); navigate('/account-settings'); }}>
                      Account Settings
                    </MenuItem> */}
                    <MenuItem onClick={() => { handleProfileMenuClose(); navigate('/payout-settings'); }}>
                      Payout Settings
                    </MenuItem>
                    
                    <MenuItem 
                      component="a" 
                      href={`/my-chat`}
                      target="_blank" 
                      rel="noopener noreferrer"
                      onClick={handleProfileMenuClose}
                    >
                      My Chat
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              ) : (
                <Box >
                  {/* <Button
                    component={Link}
                    to="/login"
                    sx={{ color: 'white', textTransform: 'none' }}
                  >
                    Login
                  </Button> */}
                  <Button
                    component={Link}
                    to="/login"
                    sx={{
                      color: 'white',
                      textTransform: 'none',
                      backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      borderRadius: '4px',
                      padding: '0 12px',
                      height:"40px",
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                    }}
                  >
                    Login
                  </Button>
                </Box>
              )}

            </Box>
          )}

          {/* Mobile Drawer */}
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box role="presentation" sx={{ width: 250 }} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
              <List>
                {/* <ListItem button component={Link} to="/categories">
                  <ListItemText primary="Categories" />
                </ListItem> */}
                <ListItem button component={Link} to="/pricing">
                  <ListItemText primary="Pricing" />
                </ListItem>
                <ListItem button component={Link} to="/features">
                  <ListItemText primary="Features" />
                </ListItem>
                <ListItem button component={Link} to="/referral-discount">
                  <ListItemText primary="Referral Discount" />
                </ListItem>

                <ListItem button component={Link} to="/create-course">
                  <ListItemText primary="Create Course" />
                </ListItem>
                <ListItem button component={Link} to="/my-teachings">
                  <ListItemText primary="My Teachings" />
                </ListItem>
                <ListItem button component={Link} to="/my-learnings">
                  <ListItemText primary="My Learnings" />
                </ListItem>

                <ListItem 
                      component="a" 
                      href={`/my-chat`}
                      target="_blank" 
                      rel="noopener noreferrer"
                      onClick={handleProfileMenuClose}
                    >
                      My Chat
                </ListItem>
                
                {user ? (
                  <>
                    <ListItem button component={Link} to="/my-profile">
                      <ListItemText primary="My Profile" />
                    </ListItem>
                    {/* <ListItem button component={Link} to="/account-settings">
                      <ListItemText primary="Account Settings" />
                    </ListItem> */}
                    <ListItem button component={Link} to="/payout-settings">
                      <ListItemText primary="Payout Settings" />
                    </ListItem>

                    <ListItem button onClick={handleLogout}>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </>
                ) : (
                  <>
                    {/* <ListItem button component={Link} to="/login">
                      <ListItemText primary="Login" />
                    </ListItem> */}
                    <ListItem button component={Link} to="/login">
                      <ListItemText primary="Login" />
                    </ListItem>
                  </>
                )}
              </List>
            </Box>
          </Drawer>
        </Toolbar>
      </AppBar>

      {/* Mobile Search Dropdown */}
      {searchDropdownOpen && (
        <Box
          sx={{
            position: 'absolute',
            top: '56px',
            left: 0,
            right: 0,
            backgroundColor: 'white',
            zIndex: 1200,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            padding: 2,
          }}
        >
          <IconButton
            sx={{ float: 'right', marginBottom: 1 }}
            onClick={toggleSearchDropdown}
          >
            <CloseIcon />
          </IconButton>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search for courses"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {/* Add padding to avoid content overlap */}

    </>
  );
}

export default Navbar;
