import React from 'react';
import { MessagesSquare } from 'lucide-react';
import './ChatIcon.css';

const ChatIcon = () => {
  return (
    <div className="chat-icon-container">
      <a 
        href="/my-chat" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="chat-icon"
      >
        <MessagesSquare size={32} className="chat-icon-svg" />
        <div className="chat-icon-pulse"></div>
        <span className="chat-tooltip">My Chat</span>
      </a>
    </div>
  );
};

export default ChatIcon;
